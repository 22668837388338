const Point = (props) => {
  return (
    <div className="three-points__point">
      <div className="three-points__line"></div>
      <div className="three-points__point-content">
        <div className="three-points__point-top">
          <img src={props.icon} alt="" className="three-points__point-icon" />
          <h2 className="three-points__point-title">{props.title}</h2>
        </div>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default Point;
