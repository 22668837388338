import { useRef, useState, useEffect } from "react";
import "./styles/App.scss";
import content from "./content.json";
import Hero from "./sections/Hero/Hero";
import Intro from "./sections/Intro/Intro";
import ThreePoints from "./sections/ThreePoints/ThreePoints";
import FourPoints from "./sections/FourPoints/FourPoints";
import Contact from "./sections/Contact/Contact";
import Footer from "./sections/Footer/Footer";
import BackToTop from "./components/BackToTop";
import Policy from "./components/Policy";

function App() {
  const contactRef = useRef(null);
  const heroRef = useRef(null);
  const introRef = useRef(null);
  const scrollDivRef = useRef(null);

  const scrollToContact = () => {
    let contactSection = contactRef.current;
    contactSection.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    let heroSection = heroRef.current;
    heroSection.scrollIntoView({ behavior: "smooth" });
  };

  const heroScroll = () => {
    let introSection = introRef.current;
    introSection.scrollIntoView({ behavior: "smooth" });
  };

  //Back to top functionality
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showButton, setShowButton] = useState(false);

  const handleVisibleButton = () => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      const position = scrollDiv.scrollTop;
      setScrollPosition(position);
    }
  };

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleVisibleButton);

      return () => {
        scrollDiv.removeEventListener("scroll", handleVisibleButton);
      };
    }
  }, []);

  useEffect(() => {
    setShowButton(scrollPosition >= 1000);
  }, [scrollPosition]);

  //Privacy Policy pop-up
  const [isPolicyOpen, setPolicyOpen] = useState(false);

  const handlePolicyClick = () => {
    setPolicyOpen(true);
  };

  return (
    <div className="scroll" ref={scrollDivRef}>
      <div className="App">
        <Hero
          content={content.hero}
          scrollToContact={scrollToContact}
          scrollDown={heroScroll}
          ref={heroRef}
        />
        <Intro content={content.intro} ref={introRef} />
        <ThreePoints content={content.threePoints} />
        <FourPoints content={content.fourPoints} />
        <Contact content={content.contact} ref={contactRef} />
        <Footer handlePolicyClick={handlePolicyClick} />
      </div>
      {showButton && <BackToTop scrollToTop={scrollToTop} />}
      {isPolicyOpen && <Policy onClose={() => setPolicyOpen(false)} />}
    </div>
  );
}

export default App;
