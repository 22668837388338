import React from "react";
import video from "../../assets/videos/hero.mov";
import image from "../../assets/images/hero-mobile.png";
import arrow from "../../assets/images/down-arrow.png";
import Header from "../Header/Header";

const Hero = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} className="slide">
      <div className="hero">
        <Header buttonClick={props.scrollToContact} />
        <video autoPlay muted loop playsInline className="hero__video">
          <source src={video} type="video/mp4"></source>
        </video>
        <img src={image} alt="" className="hero__image" />
        <div className="hero__overlay"></div>
        <div className="hero__content">
          <h2 dangerouslySetInnerHTML={{ __html: props.content.title }}></h2>
        </div>
        <div className="hero__button">
          <button onClick={props.scrollDown} className="hero__button-inner">
            <img src={arrow} alt="down arrow" className="hero__button-icon" />
          </button>
        </div>
      </div>
    </section>
  );
});

export default Hero;
