const Footer = (props) => {
  return (
    <footer className="footer slide">
      <div className="footer__section">
        <p className="footer__policy" onClick={props.handlePolicyClick}>
          Privacy Policy
        </p>
      </div>
      <div className="footer__section">
        <p className="footer__text">
          Website designed and built by&nbsp;
          <a
            className="footer__link"
            href="https://www.ocyan.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            Ocyan
          </a>
        </p>
        <p className="footer__text">© 2024 INSAITU. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
