import first from "../../assets/images/step-1.png";
import second from "../../assets/images/step-2.png";
import third from "../../assets/images/step-3.png";
import fourth from "../../assets/images/step-4.png";
import Point from "./Point";

const FourPoints = (props) => {
  const points = props.content.points;
  return (
    <section className="four-points slide">
      <h1
        className="four-points__points-title"
        dangerouslySetInnerHTML={{ __html: props.content.title }}
      ></h1>
      <div className="four-points__container">
        <Point icon={first} title={points[0].title} text={points[0].text} />
        <Point icon={second} title={points[1].title} text={points[1].text} />
        <Point icon={third} title={points[2].title} text={points[2].text} />
        <Point icon={fourth} title={points[3].title} text={points[3].text} />
      </div>
    </section>
  );
};

export default FourPoints;
