import brain from "../../assets/images/brain.png";
import cloud from "../../assets/images/cloud.png";
import magnifyingGlass from "../../assets/images/magnifying-glass.png";
import Point from "./Point";

const ThreePoints = (props) => {
  const points = props.content.points;
  return (
    <section className="three-points slide">
      <h1
        className="three-points__title"
        dangerouslySetInnerHTML={{ __html: props.content.title }}
      ></h1>
      <div className="three-points__container">
        <Point icon={brain} title={points[0].title} text={points[0].text} />
        <Point
          icon={magnifyingGlass}
          title={points[1].title}
          text={points[1].text}
        />
        <Point icon={cloud} title={points[2].title} text={points[2].text} />
      </div>
    </section>
  );
};

export default ThreePoints;
