import cross from "../assets/images/cross.png";

const Policy = ({ onClose }) => {
  return (
    <div className="policy">
      <div className="policy__content">
        <button className="policy__button" onClick={onClose}>
          <img src={cross} alt="close" />
        </button>
        <div>
          <h2>Privacy Policy</h2>
          <p className="policy__text">
            At INSAITU LTD (hereinafter referred to as the "Company") we
            recognize that privacy is important to you.
          </p>
          <p className="policy__text">
            This Privacy Policy explains how the Company and all its affiliates
            collect, use and disclose your personal data, and your rights in
            relation to the personal data as these are held. Keeping your data
            secure and private is part of our philosophy for delivering high
            standards of services.
          </p>
          <p className="policy__text">
            In this privacy policy, “us’, “we’, “our” is the data controller of
            your personal data and is subject to the EU General Data Protection
            Regulation 2016/679 (“GDPR”) and any locally applicable data
            protection laws.
          </p>
        </div>

        <div>
          <h3>How we collect your data</h3>
          <p className="policy__text">
            We collect your personal data in the manner described below:
          </p>
          <p className="policy__text">
            • From information about you provided to us by you directly;
          </p>
          <p className="policy__text">
            • When you communicate with us in writing, by telephone, fax,
            website registration, email or other digital means. In this respect,
            we may monitor, record and store any such communication;{" "}
          </p>
          <p className="policy__text">
            • When you complete (or we complete on your behalf) client
            on-boarding or application or forms;
          </p>
          <p className="policy__text">
            • Through our mobile applications and/or software developed by the
            Company
          </p>
        </div>

        <div>
          <h3>Data we collect</h3>
          <p className="policy__text">
            We collect the following categories of personal data about you:{" "}
          </p>
          <p className="policy__text">
            • Your name and contact information such as your home or business
            address, profession, email address and telephone number;{" "}
          </p>
          <p className="policy__text">
            • Biographical information which may confirm your identity including
            your date of birth, and your passport number or national identity
            card details, and/or your nationality along with the relevant copies
            of the aforementioned documentation, training data, health & injury
            profile data ; and
          </p>
          <p className="policy__text">
            • When you complete (or we complete on your behalf) client
            on-boarding or application or forms;
          </p>
          <p className="policy__text">
            • Information about your employment, education, family or personal
            circumstances, and interests, where relevant;
          </p>
        </div>

        <div>
          <h3>Processing your personal data</h3>
          <h4 className="policy__subheading">
            1. Performance of a contract with you
          </h4>
          <p className="policy__text">
            We process your personal data because it is necessary for the
            performance of a services provision agreement to which you are a
            party or in order to take steps at your request prior to entering
            into such agreement or it is necessary for the proper functioning of
            an application or algorithm or program developed by the Company and
            the data generation.
          </p>
          <p className="policy__text">
            In this respect, we use your personal data for the following:
          </p>
          <p className="policy__text">
            • To provide you with the services as set out in our services
            agreement with you
          </p>
          <p className="policy__text">
            • For any other purpose for which you provide us with your personal
            data.
          </p>
          <p className="policy__text">
            In this respect, we may share your personal data with the following:
          </p>
          <p className="policy__text">
            • Third parties whom we engage to assist in delivering the services
            to you, including other affiliates of the Company;
          </p>
          <p className="policy__text">
            • Our professional advisers where it is necessary for us to obtain
            their advice or assistance, including lawyers, accountants, IT;
          </p>
          <p className="policy__text">
            • The courts of the Republic of Cyprus and/or any other public
            authorities of the Republic of Cyprus, or where requested, either by
            Law or by agreement, for providing our services to you.
          </p>

          <h4 className="policy__subheading">2. Legitimate interests</h4>
          <p className="policy__text">
            We also process your personal data because it is necessary for our
            legitimate interests, or sometimes where it is necessary for the
            legitimate interests of another person.
          </p>
          <p className="policy__text">
            In this respect, we use your personal data for the following:
          </p>
          <p className="policy__text">
            • For marketing to you. In this respect, see the separate section on
            Marketing below;
          </p>
          <p className="policy__text">
            • Training our staff or monitoring their performance;
          </p>
          <p className="policy__text">
            • For the administration and management of our business and
            archiving or statistical analysis;
          </p>
          <p className="policy__text">
            • Seeking advice on our rights and obligations, such as where we
            require our own legal advice.
          </p>
          <p className="policy__text">
            In this respect we will share your personal data with the following:
          </p>
          <p className="policy__text">
            • Our advisers or agents where it is necessary for us to obtain
            their advice or assistance;
          </p>
          <p className="policy__text">
            We may rely on your freely given consent at the time you provided
            your personal data to us either in writing or by using an
            application or software of the Company for a purpose of the process
            other than for the purposes set out hereinabove, then the lawfulness
            of such processing is based on that consent. You have the right to
            withdraw consent at any time. However, any processing of personal
            data will not be affected prior to the receipt of the withdrawal.
          </p>

          <h4 className="policy__subheading">3. Consent</h4>
          <p className="policy__text">
            We also process your personal data because it is necessary for our
            legitimate interests, or sometimes where it is necessary for the
            legitimate interests of another person.
          </p>

          <h4>4. Compliance with legal obligations</h4>
          <p className="policy__text">
            We process your personal data because it is necessary for the
            performance of a services provision agreement to which you are a
            party or in order to take steps at your request prior to entering
            into such agreement or it is necessary for the proper functioning of
            an application or algorithm or program developed by the Company and
            the data generation.
          </p>
          <p className="policy__text">
            We also process your personal data for our compliance with a legal
            obligation which we are under.
          </p>
          <p className="policy__text">
            In this respect, we will use your personal data for the following:
          </p>
          <p className="policy__text">
            • As required by tax authorities or any competent court or legal
            authority under the relevant laws.
          </p>
          <p className="policy__text">
            In this respect, we will share your personal data with the
            following:
          </p>
          <p className="policy__text">
            • Our advisers where it is necessary for us to obtain their advice
            or assistance;
          </p>
          <p className="policy__text">
            • Our auditors where it is necessary as part of their auditing
            functions;
          </p>
          <p className="policy__text">
            • Our affiliates who are assisting us in processing any personal
            data for statistical analysis.
          </p>
          <p className="policy__text">
            • With relevant regulators or law enforcement agencies where we are
            required to do so under relevant laws.
          </p>
        </div>

        <div>
          <h3>Marketing</h3>
          <p className="policy__text">
            We may send you marketing about services we provide which may be of
            interest to you, as well as other information in the form of alerts,
            newsletters and invitations to events or functions which we believe
            might be of interest to you or in order to update you with
            information (such as legal or commercial news) which we believe may
            be relevant to you.
          </p>
          <p className="policy__text">
            Your data is not shared outside of INSAITU.
          </p>
          <p className="policy__text">
            We will communicate this to you in a number of ways including by
            post, telephone, email or other digital channels.
          </p>
          <p className="policy__text">
            If you object to receiving marketing from us at any time, please
            contact us by email: dpo@insaitu.com
          </p>
          <p className="policy__text">
            If you have given consent and you wish to withdraw it at any time,
            please contact us on the above e-mail.
          </p>
        </div>

        <div>
          <h3>
            Transfer and processing of your personal data outside the European
            Union
          </h3>
          <p className="policy__text">
            We may send you marketing about services we provide which may be of
            interest to you, as well as other information in the form of alerts,
            newsletters and invitations to events or functions which we believe
            might be of interest to you or in order to update you with
            information (such as legal or commercial news) which we believe may
            be relevant to you.
          </p>
          <p className="policy__text">
            When sharing your personal data with third parties as set out in
            this Privacy Policy, it may be transferred outside the European
            Union. Such third parties have access to personal data solely for
            the purposes of performing the services specified in the applicable
            service agreement, and not for any other purpose. In these
            circumstances, your personal data will only be transferred on one of
            the following cases:
          </p>
          <p className="policy__text">
            • The country that we send the personal data to is approved by the
            European Commission as providing an adequate level of protection for
            personal data;
          </p>
          <p className="policy__text">
            • The transfer is to a recipient in the United States of America who
            has registered under the EU/US Privacy Shield;
          </p>
          <p className="policy__text">
            • The recipient has entered into European Commission standard
            contractual clauses with us or contract terms ensuring adequate data
            protection; or
          </p>
          <p className="policy__text">
            • You have explicitly consented to the same.
          </p>
        </div>

        <div>
          <h3>Retention of you data</h3>
          <p className="policy__text">
            Your contact information and personal data are stored securely,
            using a mixture of encryption, password protection, and
            servers/back-ups all kept with multiple lock protection.
          </p>
          <p className="policy__text">
            We have put in place appropriate technical and organisational
            measures including physical, electronic and procedural measures to
            protect personal data from loss, misuse, alteration or destruction.
            We restrict access to information at our offices so that only
            officers and/or employees who need to know the information have
            access to it. Those individuals who have access to the data are
            required to maintain the confidentiality of such information. Please
            be aware that users should also take care with how they handle and
            disclose their personal data and should avoid sending personal data
            through insecure email.
          </p>
        </div>

        <div>
          <h3>Your rights under GDPR</h3>
          <p className="policy__text">
            Under the GDPR you have the following rights:
          </p>
          <p className="policy__text">
            • To obtain access to, and copies of, the personal data that we hold
            about you;
          </p>
          <p className="policy__text">
            • To require that we cease processing your personal data if the
            processing is causing you damage or distress;
          </p>
          <p className="policy__text">
            • To require us not to send you marketing communications;
          </p>
          <p className="policy__text">
            • To require us to erase your personal data;
          </p>
          <p className="policy__text">
            • To require us to restrict our data processing activities;
          </p>
          <p className="policy__text">
            • To receive from us the personal data we hold about you which you
            have provided to us, in a reasonable format specified by you,
            including for the purpose of you transmitting that personal data to
            another data controller; and
          </p>
          <p className="policy__text">
            • To require us to correct the personal data we hold about you if it
            is incorrect.
          </p>
          <p className="policy__text">
            Please note that the above rights are not absolute, and we may be
            entitled to refuse requests where exceptions apply.
          </p>
        </div>

        <div>
          <h3>Data Protection Officer</h3>
          <p className="policy__text">
            We have designated a Data Protection Officer (“DPO”), who is
            responsible to monitor compliance with this privacy policy as well
            as the applicable Laws and liaise with the relevant authorities.
          </p>
          <p className="policy__text">
            The DPO may be contacted directly with regards to all matters
            concerning this policy and the processing of your personal data
            including the enforcement of all applicable and available rights.
          </p>
          <p className="policy__text">
            Official requests may be made electronically at: dpo@insaitu.com
          </p>
          <p className="policy__text">
            For any complaints you may have you may contact the Data
            Commissioner of the Republic of Cyprus at
            http://www.dataprotection.gov.cy
          </p>
        </div>

        <div>
          <h3>Changes to this policy</h3>
          <p className="policy__text">
            Please note that this Privacy Policy may change from time to time.
            However, we will not reduce your rights under this Policy without
            your explicit consent. Any Policy changes will be duly posted on
            this page and each version of this Policy will be identified at the
            top of the page by its effective date.
          </p>
        </div>

        <p className="policy__close" onClick={onClose}>
          Close
        </p>
      </div>
    </div>
  );
};

export default Policy;
