import React from "react";

const Intro = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} className="intro slide">
      <div className="intro__content">
        <h1>{props.content.title}</h1>
        {props.content.text.map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      </div>
    </section>
  );
});

export default Intro;
