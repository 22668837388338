const Point = (props) => {
  return (
    <div className="four-points__point">
      <div className="four-points__point-top">
        <img src={props.icon} alt="" className="four-points__point-icon" />
        <h2 className="four-points__point-title">{props.title}</h2>
      </div>
      <p className="four-points__point-text">{props.text}</p>
    </div>
  );
};

export default Point;
