import logo from "../../assets/images/logo.png";

const Header = (props) => {
  return (
    <header className="header">
      <div className="header__logo">
        <img className="header__logo-img" src={logo} alt="Insaitu Logo" />
      </div>
      <button className="header__button" onClick={props.buttonClick}>
        GET IN TOUCH
      </button>
    </header>
  );
};

export default Header;
