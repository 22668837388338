import React from "react";
import ContactForm from "./ContactForm";

const Contact = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} className="contact slide">
      <h1
        className="contact__title"
        dangerouslySetInnerHTML={{ __html: props.content.title }}
      ></h1>
      <div className="contact__wrapper">
        <div className="contact__container">
          <div className="contact__content">
            <p>{props.content.text}</p>
            <br></br>
            <p>Alternatively, e-mail us directly:</p>
            <a
              href={`mailto:${props.content.email}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.content.email}
            </a>
          </div>
          <div className="contact__form-wrapper">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Contact;
