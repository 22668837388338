import arrow from "../assets/images/up-arrow.png";

const BackToTop = (props) => {
  return (
    <button onClick={props.scrollToTop} className="backToTop">
      <img src={arrow} alt="Scroll to Top" />
    </button>
  );
};

export default BackToTop;
